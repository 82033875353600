/* * {
  border: red 1px dotted;
} */

:root {
  --night-gradient: linear-gradient(to top, #7e83ab, #413e61);
  --day-gradient: linear-gradient(to top, #f0cc6f, #dda91a);
  --background-color: rgb(186, 197, 200, 0.80);
  --popup-background-color: rgb(186, 197, 200); 
  --footer-background-color: rgba(128, 137, 139, 0.8);
  --darker-contrast-color: rgb(240, 137, 52);
  --dark-contrast-color: rgb(255, 203, 161);
  --light-contrast-color:  rgb(47, 57, 112);
  --lighter-contrast-color:  rgb(95, 103, 153);
  --button-text-color: white;
  --text-color: black;
  --country-text-shadow: rgba(0, 0, 0, 0.198); 
}


[data-theme="dark"] {
  --night-gradient: linear-gradient(to top, #7e83ab, #413e61);
  --day-gradient: linear-gradient(to top, #f0cc6f, #dda91a);
  --background-color: rgb(17, 17, 17, 0.90);
  --popup-background-color: rgb(17, 17, 17);
  --footer-background-color: rgba(0, 0, 0, 0.9);
  --darker-contrast-color: rgb(3, 174, 210);
  --dark-contrast-color: rgb(104, 210, 232);
  --light-contrast-color: rgb(225, 195, 62);
  --lighter-contrast-color: rgb(254, 239, 173);
  --button-text-color: black;
  --text-color: white;
  --country-text-shadow: rgba(255, 255, 255, 0.074); 

}


html {
  scroll-behavior: smooth;
  height: 100%;
  background-color: var(--background-color);
  margin: 0%;
}


body {
  z-index: 2;
  background-image: url('./components/background.png');
  /* background-size: cover; */
  margin: 0%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 
@media (orientation: portrait) {
  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
} 
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
}


.section {
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 4vw;
  min-height: 100vh;
  transition: background-color 0.25s ease-in-out;
}


.container {
  display: flex;
  gap: 1rem;
}
@media (orientation: portrait) {
  .container {
    margin-top: -10vh;
    display: flex;
    flex-direction: column;
  }
} 
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
  .container {
    margin-top: 0vh;
    display: flex;
  }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .container {
    margin-top: 0vh;
    display: flex;
  }
}

.loading {
  animation: flash 0.8s alternate infinite;
  z-index: 2; /* Animation properties */
}

.disabled {
  opacity: 0.6;
  cursor: no-drop; /* Indicate element is not clickable */
  pointer-events: none; /* Prevent user interaction */
}

@keyframes flash {
  from { color: rgb(67, 67, 67) } /* Start from white */
  to { color: rgb(192, 192, 192); } /* Animate to lightgrey */
}

h1 {
  color: var(--light-contrast-color);
  font-family: 'General Sans', sans-serif;
  
}

h2 {
  font-size: 32px;
  color: var(--light-contrast-color);
  font-family: 'General Sans', sans-serif;
  
}

h3 {
  color: var(--light-contrast-color);
  font-family: 'General Sans', sans-serif;
}

h4 {
  color: var(--light-contrast-color);
  font-family: 'General Sans', sans-serif;
}

p {
  color: var(--text-color);
  font-family: 'Gambetta', serif;
}



/* @media (orientation: portrait) {
  body {

  }
} 
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
  body {

  }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  body {

  }
} */
