.header {
    z-index: 2;
    background-color: transparent;
    background-blend-mode: exclusion;
    transition: background-color 0.25s ease-in-out;
    margin: 0vh 0vw 0vh 0vw;
    display: flex;
    position: fixed;
    top: 0vh;
    height: 8vh; 
    width: 100%; 
}
@media (orientation: portrait) {
    .header {
    height: 6vh;
    }
} 
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
    .header {
        height: 8vh;
    }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
    .header {
        height: 8vh;
    }
}

.header img {
    margin-left: 2rem;
    margin-right: 2rem;
    height: 4rem;
}
@media (orientation: portrait) {
    .header img {
        height: 3rem;
        margin-left: 1rem;
    }
} 
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
    .header img {
        height: 2.5rem;
        margin-top: -0.2rem;
    }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
    .header img {
        margin-top: 1rem;
        margin-top: -0.2rem;
    }
}

.header img:hover {
    filter: blur(0.5px)
}

a {
    text-decoration: none;
}

.header-about {
    text-decoration: none;
    color: var(--text-color); /* Or specify your desired color */
}

.header-about:hover,
.header-about:focus,
.header-about:active {
    text-decoration: none;
    color: var(--text-color); /* Or specify a hover color */
}


.toggle-container {
    margin-top: 0rem;
    z-index: 3;
    position: absolute;
    right: 3.5em;
}

@media (orientation: portrait) and (-webkit-min-device-pixel-ratio: 3) {
    .toggle-container {
        margin-top: -0.6rem;
        right: 1rem;
    }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
    .toggle-container{
        margin-top: -1rem;
        right: 1rem;
    }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
    .toggle-container{
        margin-top: -1rem;
        right: 2rem;
    }
}

.toggle {
    visibility: hidden;
}

.toggle + label {
    display: flex;
    align-items: center;
    font-size: 1.2em;
    cursor: pointer;
    color: var(--text-color);
    font-family: "Roboto Mono", monospace;
}

.toggle + label::before {
    content: '';
    height: 1em;
    width: 2em;
    border-radius: 2em;
    background-color: var(--light-contrast-color);
    margin-right: 0.5em;
    transition: background-color 250ms ease-in-out;
}

.toggle + label::after {
    content: '';
    height: 0.8em;
    width: 0.8em;
    border-radius: 1em;
    background-color: var(--lighter-contrast-color);
    position: absolute;
    left: 0.2em;
    transition: background-color 250ms ease-in-out, transform 250ms ease-in-out;
}

.toggle:checked + label::after {
    transform: translateX(100%);
}