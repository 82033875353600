.section-footer {
    padding-top: 2rem;
    margin-top: 0px;
    background-color: var(--footer-background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.footer-elements {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.footer-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 100%;
    text-decoration: none;
}

.footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}

.footer-links a {
    color: inherit;  /* Inherits the text color from the parent element */
    text-decoration: none;
}

i {
    font-size: 24px;
    color: var(--text-color);
}

.footer-about {
    max-width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-about p {
    text-align: justify;
    height: 170px;
    overflow-y: scroll;
}
@media (orientation: portrait) {
    .footer-about p {
    overflow-y: scroll;
    height: 170px;
    }
} 
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
    .footer-about p {
        overflow-y: scroll;
        height: 170px;
    }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
    .footer-about p {
        overflow-y: scroll;
        height: 170px;
    }
}

.footer-rights p {
    color: rgb(255, 255, 255);
}
