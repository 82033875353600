.earth-animation {
    display: flex;
    position: relative;
    width: 50%;
    height: 30rem;
}
@media (orientation: portrait) {
    .earth-animation {
        height: 14rem;
        width: 100%;
    }
} 
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
    .earth-animation {
        height: 19rem;
    }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
    .earth-animation {
        height: 19rem;
    }
}

.hero-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
@media (orientation: portrait) {
    .hero-text {
        width: 100%;
        align-items: center;
    }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
    .hero-text  {
        width: 70%;
    }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
    .hero-text  {
        width: 70%;
    }
} 



.hero-text h1 {
    font-size: 80px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
@media (orientation: portrait) {
    .hero-text h1 {
        
        font-size: 40px;
    }
} 
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
    .hero-text h1 {
        font-size: 50px;
        margin: 0rem;
    }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
    .hero-text h1 {
        font-size: 50px;
        margin: 0rem;
    }
}

.hero-text p {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    font-size: 20px;

}
@media (orientation: portrait) {
    .hero-text p {       
        font-size: 13px;
    }
} 
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
    .hero-text p {
        font-size: 13px;
    }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
    .hero-text p {
        font-size: 13px;
    }
}


.hero-text button {
    margin-top: 3rem;
    height: 20px;
}
@media (orientation: portrait) {
    .hero-text button {
        margin-top: 0rem;
    }
} 


/* CSS */
.hero-text button {
    margin-top: 1rem;
    height: 4rem;
    width: 16rem;
    background-color: var(--light-contrast-color);
    border-radius: 12px;
    color: var(--button-text-color);
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    transition: 200ms;
    box-sizing: border-box;
    border: 0;
    font-size: 24px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
    .hero-text button {
        margin: 0rem;
        height: 2.5rem;
        width: 8rem;
        font-size: 16px;
    }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
    .hero-text button {
        margin: 0rem;
        height: 2.5rem;
        width: 8rem;
        font-size: 16px;
    }
}

.hero-text button:not(:disabled):hover {
    outline: 0;
    background: var(--lighter-contrast-color);
    box-shadow: 0 0 0 2px rgba(0,0,0,.2), 0 3px 8px 0 rgba(0,0,0,.15);
}

.hero-text button:disabled {
    filter: saturate(0.2) opacity(0.5);
    -webkit-filter: saturate(0.2) opacity(0.5);
    cursor: not-allowed;
    }