.travelbot-container {
    margin-left: 1rem;
    background-color: rgba(77, 77, 77, 0.402);
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    border-radius: 5%;
    height: 36rem;
    width: 42rem;
    flex-direction: column;
    justify-content: space-between;
}
@media (orientation: portrait) {
    .travelbot-container {
        height: 24rem;
        width: 100%;
        margin-left: 0rem;
    }
} 
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
    .travelbot-container {
        height: 18rem;
        width: 18rem;
    }
}
@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
    .travelbot-container {
        height: 18rem;
        width: 18rem;
    }
}

.travel-bot-title {
    gap: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title-icon {
    height: 30px;
}

.ai-reply-container {
    overflow: scroll;
    height: 60%;
    margin-right: 2rem;
    margin-left: 2rem;

}

.travelbot-container p {
    width: 100%;
}

.travelbot-input {
    gap: 0.5rem;
    border-radius: 5px;
    margin: 2rem;
    display: flex;
    flex-direction: row;
    height: 2rem;
    width: -webkit-fill-available;
    align-items: center;
}

.input {
    width: inherit;
    border-radius: 10px;
    outline: 2px solid var(--light-contrast-color);
    border: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #e2e2e2;
    outline-offset: 3px;
    padding: 10px 1rem;
    transition: 0.25s;
  }
  
  .input:focus {
    outline-offset: 5px;
    background-color: #fff
  }

  .travelbot-input i {
    color: black;
    background-color: transparent; /* Remove background if needed */
    border: none;  /* Remove borders if needed */
    padding: 0;  /* Remove padding if needed */
    font-size: 1.4rem;  /* Adjust font size as needed */
    cursor: pointer;  /* Set cursor to pointer on hover */
    margin-left: -2.7rem;
}

.travelbot-input i:hover {
    color: rgb(117, 117, 117);  /* Adjust hover color if needed */
}

.loading-response {
    animation: flash-background 0.8s alternate infinite;
    z-index: 2; /* Animation properties */
}

@keyframes flash-background {
    from { background-color: rgb(67, 67, 67) } /* Start from white */
    to { background-color: rgb(192, 192, 192); } /* Animate to lightgrey */
  }